import { render, staticRenderFns } from "./Lgx297738824409153536.vue?vue&type=template&id=1bd3405e&"
import script from "./Lgx297738824409153536.vue?vue&type=script&lang=js&"
export * from "./Lgx297738824409153536.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports