/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-3/5">
        <vx-card>
          <h1 class="font-light">Your Tickets</h1>
          <div class="vx-row mt-6">
            <div class="vx-col w-1/5">
              <h3 class="font-bold">Ticket ID</h3>
            </div>
            <div class="vx-col w-2/5">
              <h3 class="font-bold">Subject</h3>
            </div>
            <div class="vx-col w-1/5">
              <h3 class="font-bold">Date</h3>
            </div>
            <div class="vx-col w-1/5">
              <h3 class="font-bold">Priority</h3>
            </div>
          </div>
          <div
            class="vx-row mt-3 border-solid rounded m-1 p-2 cursor-pointer"
            :class="currentTab === 0 ? 'border-success' : 'border-white'"
            @click="currentTab = 0"
          >
            <div class="vx-col p-0 w-1/5">
              <h3 class="font-bold">#8675309</h3>
            </div>
            <div class="vx-col p-0 w-2/5 truncate">
              <h3 class="font-light">My recently installed printer is not working and is offline</h3>
            </div>
            <div class="vx-col w-1/5">
              <h3 class="font-bold">1/16/2020</h3>
            </div>
            <div class="vx-col p-0 w-1/5">
              <h3 class="bg-danger-gradient text-center font-bold rounded">HIGH</h3>
            </div>
          </div>
          <div
            class="vx-row mt-3 border-solid rounded m-1 p-2 cursor-pointer"
            :class="currentTab === 1 ? 'border-success' : 'border-white'"
            @click="currentTab = 1"
          >
            <div class="vx-col p-0 w-1/5">
              <h3 class="font-bold">#4928593</h3>
            </div>
            <div class="vx-col p-0 w-2/5 truncate">
              <h3 class="font-light">I just installed a new program and now my Windows PC keeps showing this error on startup.</h3>
            </div>
            <div class="vx-col w-1/5">
              <h3 class="font-bold">1/16/2020</h3>
            </div>
            <div class="vx-col p-0 w-1/5">
              <h3 class="bg-primary-gradient text-center font-bold rounded">LOW</h3>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-2/5">
        <vx-card v-if="currentTab === 0">
          <div class="flex items-center">
            <h2 class="font-bold">Ticket Details <span class="font-light">#8675309</span></h2>
          </div>
          <div class="flex items-center mt-4">
            <h2 class="font-bold">Status <span class="font-light">Opened</span></h2>
          </div>
          <div class="flex items-center mt-4">
            <h2 class="font-bold">Priority <span class="font-light text-danger">High</span></h2>
          </div>
          <div class="flex items-center mt-4">
            <h2 class="font-bold">Category <span class="font-light">Technical Bug Reports</span></h2>
          </div>
          <div class="flex items-center mt-4">
            <h2 class="font-bold">
              Assigned To <span class="font-light">{{ activeUserInfo.username }}</span>
            </h2>
          </div>
          <div class="flex items-center mt-4">
            <h2 class="font-bold">Assigned Date <span class="font-light">1/16/2020</span></h2>
          </div>
          <div class="w-full rounded mt-4 mb-4" style="height: 4px; background: #ffffff"></div>
          <div class="flex items-center">
            <h2 class="font-bold">Subject <span class="font-light">My recently installed printer is not working and is offline</span></h2>
          </div>
          <div class="mt-4">
            <h2 class="font-bold">Attachments <span class="font-light">(2)</span></h2>
            <h2 class="text-primary underline cursor-pointer">printer_properties.png</h2>
            <h2 class="text-primary underline cursor-pointer">networkconfig.png</h2>
          </div>

          <div class="mt-4">
            <h2 class="font-bold mr-2">Issue</h2>
            <v-select
              placeholder="Select an option from the dropdown"
              class="w-full"
              :options="issueOptions"
              v-model="ticketOne.selectIssue"
            />
          </div>
          <div class="mt-1">
            <small v-if="ticketOne.correctIssue !== null && ticketOne.correctIssue === false" class="text-danger font-semibold"
              >This answer is invalid</small
            >
            <small v-if="ticketOne.correctIssue !== null && ticketOne.correctIssue === true" class="text-success font-semibold"
              >This answer is correct</small
            >
          </div>

          <div class="mt-4">
            <h2 class="font-bold">Asset Affected</h2>
            <v-select placeholder="Select an option from the dropdown" class="w-full" :options="resOptions" v-model="ticketOne.selectRes" />
          </div>
          <div class="mt-1">
            <small v-if="ticketOne.correctRes !== null && ticketOne.correctRes === false" class="text-danger font-semibold"
              >This answer is invalid</small
            >
            <small v-if="ticketOne.correctRes !== null && ticketOne.correctRes === true" class="text-success font-semibold"
              >This answer is correct</small
            >
          </div>

          <div class="mt-4">
            <h2 class="font-bold">Verify / Resolve</h2>
            <v-select
              placeholder="Select an option from the dropdown"
              class="w-full"
              :options="verifyOptions"
              v-model="ticketOne.selectVerify"
            />
          </div>
          <div class="mt-1">
            <small v-if="ticketOne.correctVerify !== null && ticketOne.correctVerify === false" class="text-danger font-semibold"
              >This answer is invalid</small
            >
            <small v-if="ticketOne.correctVerify !== null && ticketOne.correctVerify === true" class="text-success font-semibold"
              >This answer is correct</small
            >
          </div>
        </vx-card>
        <vx-card v-if="currentTab === 1">
          <div class="flex items-center">
            <h2 class="font-bold">Ticket Details <span class="font-light">#4928593</span></h2>
          </div>
          <div class="flex items-center mt-4">
            <h2 class="font-bold">Status <span class="font-light">Opened</span></h2>
          </div>
          <div class="flex items-center mt-4">
            <h2 class="font-bold">Priority <span class="font-light">Low</span></h2>
          </div>
          <div class="flex items-center mt-4">
            <h2 class="font-bold">Category <span class="font-light">Technical Bug Reports</span></h2>
          </div>
          <div class="flex items-center mt-4">
            <h2 class="font-bold">
              Assigned To <span class="font-light">{{ activeUserInfo.username }}</span>
            </h2>
          </div>
          <div class="flex items-center mt-4">
            <h2 class="font-bold">Assigned Date <span class="font-light">1/16/2020</span></h2>
          </div>
          <div class="w-full rounded mt-4 mb-4" style="height: 4px; background: #ffffff"></div>
          <div class="flex items-center">
            <h2 class="font-bold">
              Subject
              <span class="font-light">I just installed a new program and now my Windows PC keeps showing this error on startup.</span>
            </h2>
          </div>
          <div class="mt-4">
            <h2 class="font-bold">Attachments <span class="font-light">(1)</span></h2>
            <h2
              class="text-primary underline cursor-pointer"
              @click="popupImage('https://connect-cdn.intellectualpoint.com/assets/images/simulations/a-plus/IRQL_NOT_LESS_OR_EQUAL.png')"
            >
              error.png - Press to View Error
            </h2>
          </div>

          <div class="mt-4">
            <h2 class="font-bold mr-2">Issue</h2>
            <v-select
              placeholder="Select an option from the dropdown"
              class="w-full"
              :options="issueOptions"
              v-model="ticketTwo.selectIssue"
            />
          </div>
          <div class="mt-1">
            <small v-if="ticketTwo.correctIssue !== null && ticketTwo.correctIssue === false" class="text-danger font-semibold"
              >This answer is invalid</small
            >
            <small v-if="ticketTwo.correctIssue !== null && ticketTwo.correctIssue === true" class="text-success font-semibold"
              >This answer is correct</small
            >
          </div>

          <div class="mt-4">
            <h2 class="font-bold">Asset Affected</h2>
            <v-select placeholder="Select an option from the dropdown" class="w-full" :options="resOptions" v-model="ticketTwo.selectRes" />
          </div>
          <div class="mt-1">
            <small v-if="ticketTwo.correctRes !== null && ticketTwo.correctRes === false" class="text-danger font-semibold"
              >This answer is invalid</small
            >
            <small v-if="ticketTwo.correctRes !== null && ticketTwo.correctRes === true" class="text-success font-semibold"
              >This answer is correct</small
            >
          </div>

          <div class="mt-4">
            <h2 class="font-bold">Verify / Resolve</h2>
            <v-select
              placeholder="Select an option from the dropdown"
              class="w-full"
              :options="verifyOptions"
              v-model="ticketTwo.selectVerify"
            />
          </div>
          <div class="mt-1">
            <small v-if="ticketTwo.correctVerify !== null && ticketTwo.correctVerify === false" class="text-danger font-semibold"
              >This answer is invalid</small
            >
            <small v-if="ticketTwo.correctVerify !== null && ticketTwo.correctVerify === true" class="text-success font-semibold"
              >This answer is correct</small
            >
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      currentTab: 0,

      issueOptions: [
        'Bad Windows Install',
        'No Active Drivers',
        'Bad Ethernet Connection',
        'Incorrect IP Address',
        'No Internet Signal',
        'Windows Not Updated',
        'Malware Detected',
        'BSOD',
        'Boot Loop',
      ],
      resOptions: [
        'Reinstall OS',
        'Rollback Drivers',
        'Rollback Updates',
        'Repair Application',
        'Create DHCP Reservation',
        'Apply Updates',
        'Remap Network Drive',
        'Reinstall OS',
        'Uninstall Application',
        'Inform user of Policy Violation',
        'Rebuild Windows Profile',
      ],
      verifyOptions: ['ping', 'Safemode', 'restart', 'cmd', 'regedit', 'taskmsc'],

      ticketOne: {
        selectIssue: null,
        correctIssue: null,
        answerIssue: 'Incorrect IP Address',
        selectRes: null,
        correctRes: null,
        answerRes: 'Create DHCP Reservation',
        selectVerify: null,
        correctVerify: null,
        answerVerify: 'ping',
      },

      ticketTwo: {
        selectIssue: null,
        correctIssue: null,
        answerIssue: 'BSOD',
        selectRes: null,
        correctRes: null,
        answerRes: 'Uninstall Application',
        selectVerify: null,
        correctVerify: null,
        answerVerify: 'Safemode',
      },
    };
  },
  methods: {
    popupImage(url) {
      window.open(
        url,
        'popUpWindow',
        'height=600,width=850,left=150,top=150,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=no',
      );
    },
    markSimulation() {
      let totalScore = 0;

      if (this.ticketOne.selectIssue === this.ticketOne.answerIssue) {
        totalScore++;
        this.ticketOne.correctIssue = true;
      } else {
        this.ticketOne.correctIssue = false;
      }

      if (this.ticketOne.selectRes === this.ticketOne.answerRes) {
        totalScore++;
        this.ticketOne.correctRes = true;
      } else {
        this.ticketOne.correctRes = false;
      }

      if (this.ticketOne.selectVerify === this.ticketOne.answerVerify) {
        totalScore++;
        this.ticketOne.correctVerify = true;
      } else {
        this.ticketOne.correctVerify = false;
      }

      if (this.ticketTwo.selectIssue === this.ticketTwo.answerIssue) {
        totalScore++;
        this.ticketTwo.correctIssue = true;
      } else {
        this.ticketTwo.correctIssue = false;
      }

      if (this.ticketTwo.selectRes === this.ticketTwo.answerRes) {
        totalScore++;
        this.ticketTwo.correctRes = true;
      } else {
        this.ticketTwo.correctRes = false;
      }

      if (this.ticketTwo.selectVerify === this.ticketTwo.answerVerify) {
        totalScore++;
        this.ticketTwo.correctVerify = true;
      } else {
        this.ticketTwo.correctVerify = false;
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    changePage(val) {
      if (val === 'next') {
        this.currentPage++;
      }
      if (val === 'previous') {
        this.currentPage--;
      }
    },
  },
  mounted() {},
  components: {
    Prism,
    vSelect,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
